/* eslint-disable no-console */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-useless-concat */
import React from 'react';
import { serialize } from 'dom-form-serializer';
import { stringify } from 'qs';
import { BsTelephoneFill } from 'react-icons/bs';
import { AiFillMail } from 'react-icons/ai';

import { toast } from 'react-toast';
import * as styles from '../../css/contact.module.css';
import Title from '../Title';

const Contact = () => {
  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    const data = serialize(form);
    if (!data.email || !data.name || !data.phone || !data.message) {
      toast.error('Please fill in all fields!', {
        position: 'top-center',
        autoClose: 5000,
      });
      return;
    }
    fetch(`${'' + '?'}${stringify(data)}`, {
      method: 'POST',
    })
      .then(res => {
        if (res.ok) {
          return res;
        }
        throw new Error('Network error');
      })
      .then(() => {
        toast.success('Message sent successfully!', {
          position: 'top-center',
          autoClose: 5000,
        });
        form.reset();
      })
      .catch(err => {
        toast.error('Your message was not sent, please try again!', {
          position: 'top-center',
          autoClose: 5000,
        });
        console.error(err);
      });
  };
  return (
    <div>
      <section className={styles.contact}>
        <Title title="contact" subtitle="us" />

        <div className={styles.center}>
          <div
            style={{
              textAlign: 'center',
              marginBottom: 32,
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ marginTop: 2 }}>
                <BsTelephoneFill />
              </div>

              <a
                rel="noopener noreferrer"
                href="tel:07941050153"
                title="Twitter"
              >
                &nbsp;&nbsp;07941 050153
              </a>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ marginTop: 4 }}>
                <AiFillMail style={{ height: 16 }} />
              </div>
              <a
                rel="noopener noreferrer"
                href="mailto:info@scoutingforbands.co.uk"
                title="Twitter"
              >
                &nbsp;&nbsp;info@scoutingforbands.co.uk
              </a>
            </div>
          </div>
          <form
            action=""
            name="Scouting_For_Bands_Contact_Form"
            method="POST"
            onSubmit={handleSubmit}
            netlify-honeypot="bot-field"
            data-netlify="true"
          >
            <p className="ohnohoney">
              <label>
                Don’t fill this out if you're human: <input name="bot-field" />
              </label>
            </p>
            <div>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                className={styles.formControl}
                placeholder=""
              />
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                className={styles.formControl}
                placeholder=""
              />
            </div>
            <div>
              <label htmlFor="phone">Phone</label>
              <input
                type="text"
                name="phone"
                id="phone"
                className={styles.formControl}
                placeholder=""
              />
            </div>
            <div>
              <label htmlFor="message">Message</label>
              <textarea
                name="message"
                id="message"
                rows="10"
                className={styles.formControl}
                placeholder=""
              />
            </div>
            <div>
              <input type="submit" value="SEND" className={styles.submit} />
              <input
                type="hidden"
                name="form-name"
                value="Scouting_For_Bands_Contact_Form"
              />
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Contact;
