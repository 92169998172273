import React from 'react';
import { graphql } from 'gatsby';
import { ToastContainer } from 'react-toast';

import Layout from '../components/Layout';
import StyledHero from '../components/StyledHero';
import Contact from '../components/Contact/Contact';
import SEO from '../components/SEO';

export default function contact({ data }) {
  return (
    <Layout>
      <ToastContainer position="top-center" delay={5000} />

      <SEO title="Contact" />
      <StyledHero
        img={
          data.contactBcg.global.contactBGImage.imageFile.childImageSharp.fluid
        }
      />
      <Contact />
    </Layout>
  );
}

export const query = graphql`
  query {
    contactBcg: directus {
      global {
        contactBGImage {
          id
          imageFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1280) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
